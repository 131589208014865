<template>
  <v-container fluid>
    <v-card elevation="0">
      <v-card-title>언론사 리포트</v-card-title>
      <v-row no-gutters class="px-5">
        <v-col cols="12" lg="3" sm="12">
          <v-autocomplete
            v-model="media_id"
            :items="media_items"
            label="언론사"
            item-text="name"
            item-value="id"
            no-data-text="존재하지 않는 언론사입니다."
            hide-details
            @change="getData()"
          >
          </v-autocomplete>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn-toggle
          v-model="interval"
          class="mx-auto mt-2 px-5"
          dense
          mandatory
        >
          <v-btn
            v-for="type in intervalType"
            :key="type.value"
            :value="type.value"
            @click="changeType(type.value)"
            class="px-3 py-5"
          >
            {{ type.text }}
          </v-btn>
        </v-btn-toggle>
        <v-col cols="12" lg="1" sm="12">
          <v-menu
            v-model="startMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="100%"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatStartDate"
                readonly
                v-bind="attrs"
                v-on="on"
                label="시작 날짜"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="startDate"
              ref="startDate"
              @input="startMenu = false"
              :max="startMaxDate"
              locale="ko-KR"
              no-title
              reactive
            ></v-date-picker>
          </v-menu>
        </v-col>
        <p class="text-center mx-auto px-5 mt-5">~</p>
        <v-col cols="12" lg="1" sm="12" class="mr-5">
          <v-menu
            v-model="endMenu"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="100%"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="formatEndDate"
                readonly
                v-bind="attrs"
                v-on="on"
                label="종료 날짜"
                hide-details
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="endDate"
              ref="endDate"
              @input="endMenu = false"
              :min="startDate"
              :max="endMaxDate"
              locale="ko-KR"
              no-title
              reactive
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-btn color="primary" @click="getData()" class="mx-auto mt-3"
          >검색</v-btn
        >
      </v-row>
      <v-row>
        <v-col class="px-7">
          <v-progress-circular
            v-if="loading"
            :indeterminate="loading"
            class="progress_circular"
          ></v-progress-circular>
          <div
            v-if="chart"
            :style="`height:${chartHeight}px;`"
            class="chart_wrap"
          >
            <div ref="chart" style="height: 100%"></div>
          </div>
        </v-col>
      </v-row>
    </v-card>
    <v-row class="mt-5 mb-16">
      <v-col>
        <v-simple-table>
          <template>
            <thead class="sticky_header">
              <tr>
                <th
                  class="text-center"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  날짜
                </th>
                <th
                  class="text-center"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  언론사 수수료
                </th>
                <th class="text-center">기사 뷰(PV)</th>
                <th
                  class="text-center"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  기사 뷰(UV)
                </th>
                <th class="text-center">설문 요청(PV)</th>
                <th
                  class="text-center"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  설문 요청(UV)
                </th>
                <th class="text-center">설문 노출(PV)</th>
                <th
                  class="text-center"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  설문 노출(UV)
                </th>
                <th class="text-center">설문 응답(PV)</th>
                <th class="text-center">설문 응답(UV)</th>
                <th
                  class="text-center"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  설문 완료
                </th>
                <th
                  class="text-center"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  CVR
                </th>
                <th class="text-center">예상매출액</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(item, i) in tableItems" :key="i">
                <td
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                  :style="{ color: getWeekend(item.date) }"
                >
                  {{
                    interval === "weekly"
                      ? getWeek(item.date)
                      : interval === "monthly"
                      ? getMonth(item.date)
                      : item.date
                  }}
                </td>
                <td
                  class="text-right"
                  :class="{
                    max: maxMinItems.settlement_max === item.settlement,
                    min: maxMinItems.settlement_min === item.settlement,
                  }"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  {{ convertComma(item.settlement) }}
                </td>
                <td
                  class="text-right"
                  :class="{
                    max: maxMinItems.view_max === item.view,
                    min: maxMinItems.view_min === item.view,
                  }"
                >
                  {{ convertComma(item.view) }}
                </td>
                <td
                  class="text-right"
                  :class="{
                    max: maxMinItems.view_device_max === item.view_device,
                    min: maxMinItems.view_device_min === item.view_device,
                  }"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  {{ convertComma(item.view_device) }}
                </td>
                <td
                  class="text-right"
                  :class="{
                    max: maxMinItems.request_max === item.request,
                    min: maxMinItems.request_min === item.request,
                  }"
                >
                  {{ convertComma(item.request) }}
                </td>
                <td
                  class="text-right"
                  :class="{
                    max: maxMinItems.request_device_max === item.request_device,
                    min: maxMinItems.request_device_min === item.request_device,
                  }"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  {{ convertComma(item.request_device) }}
                </td>
                <td
                  class="text-right"
                  :class="{
                    max: maxMinItems.impression_max === item.impression,
                    min: maxMinItems.impression_min === item.impression,
                  }"
                >
                  {{ convertComma(item.impression) }}
                </td>
                <td
                  class="text-right"
                  :class="{
                    max:
                      maxMinItems.impression_device_max ===
                      item.impression_device,
                    min:
                      maxMinItems.impression_device_min ===
                      item.impression_device,
                  }"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  {{ convertComma(item.impression_device) }}
                </td>
                <td
                  class="text-right"
                  :class="{
                    max: maxMinItems.survey_max === item.survey,
                    min: maxMinItems.survey_min === item.survey,
                  }"
                >
                  {{ convertComma(item.survey) }}
                </td>
                <td
                  class="text-right"
                  :class="{
                    max: maxMinItems.survey_device_max === item.survey_device,
                    min: maxMinItems.survey_device_min === item.survey_device,
                  }"
                >
                  {{ convertComma(item.survey_device) }}
                </td>
                <td
                  class="text-right"
                  :class="{
                    max: maxMinItems.complete_max === item.complete,
                    min: maxMinItems.complete_min === item.complete,
                  }"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  {{ convertComma(item.complete) }}
                </td>
                <td
                  class="text-right"
                  :class="{
                    max: maxMinItems.answer_rate_max === item.answer_rate,
                    min: maxMinItems.answer_rate_min === item.answer_rate,
                  }"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  {{ convertComma(item.answer_rate) }}
                </td>
                <td
                  class="text-right"
                  :class="{
                    max:
                      maxMinItems.expected_profit_max === item.expected_profit,
                    min:
                      maxMinItems.expected_profit_min === item.expected_profit,
                  }"
                >
                  {{ convertComma(item.expected_profit) }}
                </td>
              </tr>

              <tr>
                <td
                  class="font-weight-bold"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  합계
                </td>
                <td
                  class="text-right font-weight-bold"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  {{ convertComma(this.summaryItems.settlement) }}
                </td>
                <td class="text-right font-weight-bold">
                  {{ convertComma(this.summaryItems.view) }}
                </td>
                <td
                  class="text-right font-weight-bold"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  {{ convertComma(this.summaryItems.view_device) }}
                </td>
                <td class="text-right font-weight-bold">
                  {{ convertComma(this.summaryItems.request) }}
                </td>
                <td
                  class="text-right font-weight-bold"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  {{ convertComma(this.summaryItems.request_device) }}
                </td>
                <td class="text-right font-weight-bold">
                  {{ convertComma(this.summaryItems.impression) }}
                </td>
                <td
                  class="text-right font-weight-bold"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  {{ convertComma(this.summaryItems.impression_device) }}
                </td>
                <td class="text-right font-weight-bold">
                  {{ convertComma(this.summaryItems.survey) }}
                </td>
                <td class="text-right font-weight-bold">
                  {{ convertComma(this.summaryItems.survey_device) }}
                </td>
                <td
                  class="text-right font-weight-bold"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  {{ convertComma(this.summaryItems.complete) }}
                </td>
                <td
                  class="text-right font-weight-bold"
                  style="border-right: 1px solid rgba(0, 0, 0, 0.12)"
                >
                  {{ convertComma(this.summaryItems.total_answer_rate) }}
                </td>
                <td class="text-right font-weight-bold">
                  {{ convertComma(this.summaryItems.expected_profit) }}
                </td>
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>

    <ScrollTop />
  </v-container>
</template>

<script>
import * as echarts from "echarts";
import moment from "moment";
import ScrollTop from "@/components/common/ScrollTop.vue";

export default {
  name: "MediaReport",
  components: {
    ScrollTop,
  },
  data() {
    return {
      media_id: "",
      media_items: [
        {
          id: "",
          name: "전체",
        },
      ],

      interval: "daily",
      intervalType: [
        {
          text: "일간",
          value: "daily",
        },
        {
          text: "주간",
          value: "weekly",
        },
        {
          text: "월간",
          value: "monthly",
        },
      ],

      today: moment().format("YYYY-MM-DD"),
      startDate: null,
      startMenu: false,
      endDate: null,
      endMenu: false,
      startMaxDate: moment(this.endDate).format("YYYY-MM-DD"),
      endMaxDate: moment(this.today).format("YYYY-MM-DD"),
      formatStartDate: null,
      formatEndDate: null,

      // 차트옵션 공통 값
      colorPalette: [
        "#dd4343",
        "#599BFF",
        "#599BFF",
        "#FE83B7",
        "#FE83B7",
        "#FFBC57",
        "#FFBC57",
        "#26C7AA",
        "#26C7AA",
      ],

      chart: false,
      loading: false,
      chartHeight: 0,

      tableItems: [],
      maxMinItems: {},
      summaryItems: {},

      dates: [],
      views: [], // 기사 뷰
      views_device: [],
      requests: [], // 설문 요청
      requests_device: [],
      impressions: [], // 설문 노출
      impressions_device: [],
      surveys: [], // 설문 응답
      surveys_device: [],
      settlement: [],
      complete: [],

      number: 0,
    };
  },
  mounted() {
    this.initDate();
    this.getMedia();
    this.getData();
  },

  watch: {
    // 달력 타입에 따라 날짜 포맷 다르게 변경(invaild value 방지)
    startDate(v) {
      if (this.interval === "daily") {
        this.formatStartDate = v;
      } else if (this.interval === "weekly") {
        v = v.substr(0, 7);
        this.formatStartDate = v;
      } else if (this.interval === "monthly") {
        v = v.substr(0, 4);
        this.formatStartDate = v;
      }
    },
    endDate(v) {
      if (this.interval === "daily") {
        this.formatEndDate = v;
      } else if (this.interval === "weekly") {
        v = v.substr(0, 7);
        this.formatEndDate = v;
        if (
          moment(this.endDate).format("YYYY-MM") ===
          moment(this.today).format("YYYY-MM")
        ) {
          this.endDate = moment(this.today).format("YYYY-MM-DD");
        } else {
          this.endDate = moment(this.endDate)
            .endOf("month")
            .format("YYYY-MM-DD");
        }
      } else if (this.interval === "monthly") {
        v = v.substr(0, 4);
        this.formatEndDate = v;
        this.formatEndDate = v;
        if (
          moment(this.endDate).format("YYYY") ===
          moment(this.today).format("YYYY")
        ) {
          this.endDate = moment(this.today).format("YYYY-MM-DD");
        } else {
          this.endDate = moment(this.endDate)
            .endOf("year")
            .format("YYYY-MM-DD");
        }
      }
    },

    // 달력 타입 변경(라이브러리에서 제공되지 않아서 강제변경)
    startMenu(v) {
      if (this.interval === "daily") {
        v &&
          this.$nextTick(
            () => (this.$refs.startDate.internalActivePicker = "DATE")
          );
      } else if (this.interval === "weekly") {
        v &&
          this.$nextTick(
            () => (this.$refs.startDate.internalActivePicker = "MONTH")
          );
      } else if (this.interval === "monthly") {
        v &&
          this.$nextTick(
            () => (this.$refs.startDate.internalActivePicker = "YEAR")
          );
      }
    },
    endMenu(v) {
      if (this.interval === "daily") {
        v &&
          this.$nextTick(
            () => (this.$refs.endDate.internalActivePicker = "DATE")
          );
      } else if (this.interval === "weekly") {
        v &&
          this.$nextTick(
            () => (this.$refs.endDate.internalActivePicker = "MONTH")
          );
      } else if (this.interval === "monthly") {
        v &&
          this.$nextTick(
            () => (this.$refs.endDate.internalActivePicker = "YEAR")
          );
      }
    },
  },

  methods: {
    initDate() {
      this.startDate = moment(this.today).subtract(1, "M").format("YYYY-MM-DD");
      this.endDate = this.today;
      this.formatStartDate = moment(this.startDate).format("YYYY-MM-DD");
      this.formatEndDate = moment(this.endDate).format("YYYY-MM-DD");
    },

    convertComma(value) {
      if (value !== undefined) {
        return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
      }
    },

    getWeekend(date) {
      if (this.interval === "daily")
        if (moment(date).day() === 6) {
          return "blue";
        } else if (moment(date).day() === 0) {
          return "red";
        } else {
          return "rgba(0,0,0,.87)";
        }
      else {
        return "rgba(0,0,0,.87)";
      }
    },

    getWeek(date) {
      const weekOfMonth = (m) =>
        m.week() - moment(m).startOf("month").week() + 1;
      const nowDate = moment(date).utc(true);
      const final = nowDate.format("MM월 ") + weekOfMonth(nowDate) + "주";
      return final;
    },

    getMonth(date) {
      const nowDate = moment(date).utc(true);
      const final = nowDate.format("YYYY년 MM") + "월";
      return final;
    },

    changeType(type) {
      this.interval = type;
      if (type === "daily") {
        this.startDate = moment(this.today)
          .subtract(1, "M")
          .format("YYYY-MM-DD");
        this.endDate = moment(this.today).format("YYYY-MM-DD");
        this.startMaxDate = moment(this.endDate).format("YYYY-MM-DD");
        this.endMaxDate = moment(this.today).format("YYYY-MM-DD");
        this.formatStartDate = moment(this.startDate).format("YYYY-MM-DD");
        this.formatEndDate = moment(this.endDate).format("YYYY-MM-DD");
      } else if (type === "weekly") {
        this.startDate = moment(this.today)
          .subtract(2, "M")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.endDate = moment(this.today).format("YYYY-MM-DD");
        this.startMaxDate = moment(this.endDate).format("YYYY-MM") + "-NaN";
        this.endMaxDate = moment(this.today).format("YYYY-MM") + "-NaN";
        this.formatStartDate = moment(this.startDate).format("YYYY-MM");
        this.formatEndDate = moment(this.endDate).format("YYYY-MM");
      } else if (type === "monthly") {
        this.startDate = moment(this.today)
          .subtract(1, "Y")
          .startOf("year")
          .format("YYYY-MM-DD");
        this.endDate = moment(this.today).format("YYYY-MM-DD");
        this.startMaxDate = moment(this.endDate).format("YYYY") + "-NaN-NaN";
        this.endMaxDate = moment(this.today).format("YYYY") + "-NaN-NaN";
        this.formatStartDate = moment(this.startDate).format("YYYY");
        this.formatEndDate = moment(this.endDate).format("YYYY");
      }
      this.getData();
    },

    getMedia() {
      this.axios.get("api/v1/manage/list/medias").then((res) => {
        if (res.data.list.data.length > 0) {
          res.data.list.data.map((item) => {
            this.media_items.push({
              id: item.id,
              name: item.name,
            });
          });
        }
      });
    },

    resetChartField() {
      this.dates = [];
      this.tableItems = [];
      this.views = [];
      this.views_device = [];
      this.requests = [];
      this.requests_device = [];
      this.impressions = [];
      this.impressions_device = [];
      this.surveys = [];
      this.surveys_device = [];
      this.settlement = [];
      this.complete = [];
      this.maxMinItems = {};
      this.summaryItems = {};
    },

    async getData() {
      this.loading = true;
      this.resetChartField();

      let items = [];
      let date = [];
      let view = [];
      let request = [];
      let survey = [];
      let impression = [];
      let view_device = [];
      let request_device = [];
      let survey_device = [];
      let impression_device = [];
      let settlement = [];
      let complate = [];

      let params = {
        media_id: this.media_id,
        start_at: this.startDate,
        end_at: this.endDate,
        interval: this.interval,
      };
      await this.axios
        .get(`api/v1/stats/req-types`, { params })
        .then((res) => {
          if (res.data.stats.length > 0) {
            this.chart = true;
            this.chartHeight = 350;
            res.data.stats.map((el) => {
              date.push(el.date);
              view.push(el.detail.view);
              request.push(el.detail.request);
              impression.push(el.detail.impression);
              survey.push(el.detail.survey);
              view_device.push(el.detail.view_device);
              request_device.push(el.detail.request_device);
              impression_device.push(el.detail.impression_device);
              survey_device.push(el.detail.survey_device);
              complate.push(el.detail.complete);
              settlement.push(el.detail.settlement);
              items.push({
                date: el.date,
                view: el.detail.view,
                view_device: el.detail.view_device,
                request: el.detail.request,
                request_device: el.detail.request_device,
                impression: el.detail.impression,
                impression_device: el.detail.impression_device,
                survey: el.detail.survey,
                survey_device: el.detail.survey_device,
                settlement: el.detail.settlement,
                complete: el.detail.complete,
                answer_rate: el.detail.answer_rate,
                expected_profit: el.detail.expected_profit,
              });
            });
          }
          this.tableItems = items.reverse();
          this.dates = date;
          this.views = view;
          this.requests = request;
          this.surveys = survey;
          this.impressions = impression;
          this.views_device = view_device;
          this.requests_device = request_device;
          this.surveys_device = survey_device;
          this.impressions_device = impression_device;
          this.complete = complate;
          this.settlement = settlement;
          this.maxMinItems = res.data.minmax;
          this.summaryItems = res.data.summary;
        })
        .catch(() => {
          this.chart = false;
          this.loading = false;
        });

      if (this.chart) {
        this.getChart();
      }
      this.loading = false;
    },

    async getChart() {
      let max = Math.max.apply(null, this.views);
      this.number = max.toString().length;

      this.$nextTick(() => {
        const chartDom = this.$refs.chart;
        let myChart = echarts.init(chartDom);

        myChart.setOption({
          color: this.colorPalette,
          tooltip: {
            trigger: "axis",
            formatter: (params) => {
              let item = "";
              params.forEach((param) => {
                item += `<div style="display:flex;justify-content:space-between;text-align:left"><span style="margin-right:10px">${
                  param.marker
                }${
                  param.seriesName
                }</span><span style="font-weight:600">${this.convertComma(
                  param.value
                )}</span></div>`;
              });

              let intervalValue = params[0].name;
              if (this.interval === "weekly") {
                return `<p style="margin-bottom: 10px;text-align:left">${this.getWeek(
                  intervalValue
                )}</p> ${item}`;
              } else if (this.interval === "monthly") {
                return `<p style="margin-bottom: 10px;text-align:left">${this.getMonth(
                  intervalValue
                )}</p> ${item}`;
              } else {
                return `<p style="margin-bottom: 10px;text-align:left">${intervalValue}</p> ${item}`;
              }
            },
          },
          legend: {
            data: [
              "언론사 수수료",
              "기사 뷰(PV)",
              "기사 뷰(UV)",
              "설문 요청(PV)",
              "설문 요청(UV)",
              "설문 노출(PV)",
              "설문 노출(UV)",
              "설문 응답(PV)",
              "설문 응답(UV)",
            ],
            orient: "horizontal",
            top: "bottom",
          },
          grid: {
            left: "0%",
            top: "5%",
            right: "20px",
            bottom: "15%",
            containLabel: true,
          },
          xAxis: {
            type: "category",
            data: this.dates,
            axisTick: { show: false },
            axisLabel: {
              formatter: (value) => {
                if (this.interval === "daily") {
                  return value.substr(5, 10);
                } else if (this.interval === "weekly") {
                  return this.getWeek(value);
                } else if (this.interval === "monthly") {
                  return this.getMonth(value);
                }
              },
              textStyle: {
                color: (value) => {
                  if (this.interval === "daily") {
                    if (moment(value).day() === 6) {
                      return "blue";
                    } else if (moment(value).day() === 0) {
                      return "red";
                    } else {
                      return "#000";
                    }
                  } else {
                    return "#000";
                  }
                },
              },
            },
          },
          yAxis: [
            {
              type: "value",
              position: "right",
              alignTicks: true,
              axisLine: {
                show: true,
              },
            },
            {
              type: "value",
              alignTicks: true,
              position: "right",
              offset: this.number < 4 ? 40 : this.number * 11,
              axisTick: { show: false },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#26C7AA",
                },
              },
              axisLabel: {
                textStyle: {
                  color: "#26C7AA",
                },
              },
            },
            {
              type: "value",
              alignTicks: true,
              position: "left",
              axisTick: { show: false },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#dd4343",
                },
              },
              axisLabel: {
                textStyle: {
                  color: "#dd4343",
                },
              },
            },
          ],
          series: [
            {
              name: "언론사 수수료",
              type: "bar",
              yAxisIndex: 2,
              barWidth: "20px",
              data: this.settlement,
              emphasis: {
                focus: "series",
              },
            },
            {
              name: "기사 뷰(PV)",
              type: "line",
              data: this.views,
              emphasis: {
                focus: "series",
              },
              symbol: "circle",
            },
            {
              name: "기사 뷰(UV)",
              type: "line",
              data: this.views_device,
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                type: "dashed",
              },
            },
            {
              name: "설문 요청(PV)",
              type: "line",
              data: this.requests,
              emphasis: {
                focus: "series",
              },
              symbol: "circle",
            },
            {
              name: "설문 요청(UV)",
              type: "line",
              data: this.requests_device,
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                type: "dashed",
              },
            },
            {
              name: "설문 노출(PV)",
              type: "line",
              data: this.impressions,
              emphasis: {
                focus: "series",
              },
              symbol: "circle",
            },
            {
              name: "설문 노출(UV)",
              type: "line",
              data: this.impressions_device,
              emphasis: {
                focus: "series",
              },
              lineStyle: {
                type: "dashed",
              },
            },
            {
              name: "설문 응답(PV)",
              type: "line",
              yAxisIndex: 1,
              data: this.surveys,
              symbol: "circle",
            },
            {
              name: "설문 응답(UV)",
              type: "line",
              yAxisIndex: 1,
              data: this.surveys_device,
              lineStyle: {
                type: "dashed",
              },
              emphasis: {
                focus: "series",
              },
            },
          ],
        });
        window.addEventListener("resize", myChart.resize);
      });
    },
  },
};
</script>
<style scoped>
.v-application th,
.v-application td {
  white-space: nowrap;
}
.min {
  color: red;
  font-weight: 600;
}
.max {
  color: blue;
  font-weight: 600;
}
.progress_circular {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.v-data-table >>> .v-data-table__wrapper {
  overflow: unset;
}
@media all and (max-width: 1023px) {
  .v-data-table >>> .v-data-table__wrapper {
    overflow-x: auto;
    overflow-y: hidden;
  }
}
</style>
